import { createBrowserRouter, Navigate } from 'react-router-dom';
import { QuestionList } from './pages/questions/question-list';
import { MainLayout } from './components/main-layout';
import { AddQuestion } from './pages/questions/add-question';
import { EditQuestion } from './pages/questions/edit-question';
import { SignIn } from './pages/auth/sign-in';
import { StudentsList } from './pages/students/students-list';
import { RegistrationsList } from './pages/registrations/registrations';

export const routes = {
    signIn: '/sign-in',
    questions: '/questions',
    questionCreate: '/question/create',
    questionEdit: '/question/edit/:id',
    students: '/students',
    registrations: '/registrations',
};

export const router = createBrowserRouter([
    {
        path: '/sign-in',
        element: <SignIn />,
    },
    {
        element: <MainLayout />,
        children: [
            {
                path: '/',
                index: true,
                element: <Navigate to={routes.students} replace />,
            },
            {
                path: routes.questions,
                element: <QuestionList />,
            },
            {
                path: routes.questionCreate,
                element: <AddQuestion />,
            },
            {
                path: routes.questionEdit,
                element: <EditQuestion />,
            },
            {
                path: routes.students,
                element: <StudentsList />,
            },
            {
                path: routes.registrations,
                element: <RegistrationsList />,
            },
        ],
    },
]);
