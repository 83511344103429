import React from 'react';

import { Flex, Typography } from 'antd';
import { Spinner } from '../spinner';
import { Statistics } from '../../types/statistics';

const { Text } = Typography;

type StatisticsCardProps = {
    loading: boolean;
    success: boolean;
    data?: Statistics;
};

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
    loading,
    success,
    data,
}) => {

    return (
        <div>
            {loading && <Spinner />}

            {success && !data && <div>Нет данных</div>}

            {success && !!data && (
                <Flex vertical>
                    <Text type="secondary">Время ответов (в секундах): </Text>
                    <Text>Общее медианное время ответа на вопрос: {data.response_times.overall_answer_spent_time_sec}</Text>
                    <Text>Медианное время ответа выбранного студента: {data.response_times.student_answer_spent_time_sec}</Text>
                    <Text>Отношение одного к другому: {data.response_times.ratio_percents_student_to_all}%</Text>
                    <Text type="secondary">Длина ответов (в символах): </Text>
                    <Text>Общая медианная длина ответа на вопрос: {data.answer_lengths.overall_median_length}</Text>
                    <Text>Медианная длина ответа на вопрос по выбранному студенту: {data.answer_lengths.student_median_length}</Text>
                    <Text>Отношение одного к другому: {data.answer_lengths.ratio}%</Text>
                </Flex>
            )}
        </div>
    );
};
