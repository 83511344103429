import React from 'react';
import css from './chat-bubbles.module.css';
import { RbcRegQuiz } from '../../types/rbc-reg-quiz';

type ChatBubblesProps = {
    chat: RbcRegQuiz[];
};

export const ChatBubbles: React.FC<ChatBubblesProps> = ({ chat }) => {
    return (
        <div className={css.main}>
            {chat.map((d) => (
                <>
                    <p className={css.send}>{d.reg_question}</p>
                    <p className={css.receive}>{d.answer}</p>
                </>
            ))}
        </div>
    );
};
