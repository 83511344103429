import React, { useEffect } from 'react';
import { UserOutlined, QuestionOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';

import { Outlet, useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { useCookies } from 'react-cookie';
import { MenuItemType } from 'antd/es/menu/interface';

const { Header, Content, Footer } = Layout;

export const MainLayout: React.FC = () => {
    const navigate = useNavigate();

    const [cookies] = useCookies(['auth_id', 'token']);

    useEffect(() => {
        if (!cookies.auth_id || !cookies.token) {
            navigate(routes.signIn);
        }
    }, [cookies, navigate]);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const items: MenuItemType[] = [
        {
            key: 'question',
            icon: React.createElement(QuestionOutlined),
            label: 'Вопросы',
            onClick: () => navigate(routes.questions),
        },
        {
            key: 'students',
            icon: React.createElement(UserOutlined),
            label: 'Студенты',
            onClick: () => navigate(routes.students),
        },
        {
            key: 'registrations',
            icon: React.createElement(UserOutlined),
            label: 'Регистрации',
            onClick: () => navigate(routes.registrations),
        },
    ];

    return (
        <Layout>
            <Layout>
                <Header
                    style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        items={items}
                        style={{ flex: 1, minWidth: 0 }}
                    />
                </Header>
                <Content style={{ margin: '24px 16px 0' }}>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Дневник чтения Библии © {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};
