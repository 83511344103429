import React, { ChangeEventHandler, useMemo, useState } from 'react';
import { Button, Col, Flex, Input, Row, Spin, Typography } from 'antd';
import { PageSpinner } from '../../components/page-spinner';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiUrl } from '../../api/requests';
import { RbcProfile } from '../../types/rbc_profile';
import { RbcRegQuiz } from '../../types/rbc-reg-quiz';
import { Spinner } from '../../components/spinner';
import { ChatBubbles } from '../../components/chat-bubbles/chat-bubbles';

const { Title, Text } = Typography;

const { Search } = Input;

export const RegistrationsList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentStudent, setCurrentStudent] = useState<RbcProfile | null>(
        null
    );

    const {
        data: students,
        isPending: studentsLoading,
        isSuccess: studentsSuccess,
    } = useQuery({
        queryKey: ['registrations'],
        queryFn: async () => {
            const result = await axios.get(`${apiUrl}/students/profiles`, {
                withCredentials: false,
            });
            return result.data as RbcProfile[];
        },
    });

    const {
        data: regQuiz,
        isPending: regQuizLoading,
        isSuccess: regQuizSuccess,
    } = useQuery({
        queryKey: ['quiz', currentStudent],
        enabled: !!currentStudent,
        queryFn: async () => {
            const result = await axios.get(
                `${apiUrl}/students/profiles/${currentStudent?.tg_id}`,
                {
                    withCredentials: false,
                }
            );
            return result.data as RbcRegQuiz[];
        },
    });

    const onChangeSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSelectStudent = (student: RbcProfile) => {
        setCurrentStudent(student);
    };

    const filteredStudents = useMemo(() => {
        return students?.filter(
            (r) =>
                r.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                r.surname.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [students, searchTerm]);

    return (
        <Flex gap="middle" vertical>
            <Flex gap="middle" justify="space-between">
                <Title level={3} style={{ margin: 0 }}>
                    Регистрации
                </Title>
            </Flex>

            <Row>
                <Col sm={24} xs={24} lg={12}>
                    <Search onChange={onChangeSearch} allowClear />
                </Col>
            </Row>

            <Flex gap={10} wrap>
                {studentsLoading && <PageSpinner />}
                {studentsSuccess &&
                    filteredStudents?.map((s) => (
                        <Button
                            color="primary"
                            variant={
                                currentStudent?.tg_id === s.tg_id
                                    ? 'filled'
                                    : 'text'
                            }
                            key={s.tg_id}
                            onClick={() => onSelectStudent(s)}
                        >
                            {s.name} {s.surname}
                        </Button>
                    ))}
                {studentsSuccess && !students?.length && (
                    <Text>Ничего не найдено</Text>
                )}
            </Flex>

            {currentStudent && regQuizLoading && <Spinner />}
            {currentStudent && regQuizSuccess && (
                <div>
                    <ChatBubbles chat={regQuiz} />
                </div>
            )}
        </Flex>
    );
};
