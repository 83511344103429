import React from 'react';
import { Button, Flex, Form, FormProps, Input } from 'antd';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { apiUrl } from '../../api/requests';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { useCookies } from 'react-cookie';

type SignInForm = {
    username?: string;
    password?: string;
};

export const SignIn: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [, setCookie] = useCookies(['auth_id', 'token']);

    const { mutate: signIn } = useMutation({
        mutationFn: async ({ username, password }: SignInForm) => {
            const result = await axios.post(
                `${apiUrl}/auth/sign-in`,
                {
                    username,
                    password,
                },
                { withCredentials: false }
            );
            return result.data;
        },
        onSuccess: (data) => {
            setCookie('auth_id', data.id);
            setCookie('token', data.token);
            navigate(routes.students);
        },
        onError: () => {
            form.setFields([
                { name: 'username', errors: ['Не найдено'] },
                { name: 'password', errors: ['Не найдено'] },
            ]);
        },
    });

    const onFinish: FormProps<SignInForm>['onFinish'] = (values) => {
        signIn(values);
    };

    const onFinishFailed: FormProps<SignInForm>['onFinishFailed'] = (
        errorInfo
    ) => {};

    return (
        <Flex justify="center" align="center" style={{ height: '100vh' }}>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<SignInForm>
                    label="Имя пользователя"
                    name="username"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<SignInForm>
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
    );
};
