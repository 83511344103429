import React from 'react';
import { Card, Col, Flex, Row, Space, Typography } from 'antd';
import { Spinner } from '../spinner';
import {
    ContentTypeEnum,
    QuestionTypeTitleEnum,
} from '../../types/content-type.enum';
import { Registration } from '../../types/registration';
import { Attendance } from '../../types/attendance';
import { DiaryStatus } from '../../types/diary-status';
import { DiaryStatusCard } from './diary-status-card';
import { Statistics } from '../../types/statistics';
import { StatisticsCard } from './statistics-card';

const { Title, Text } = Typography;

type AttendanceProps = {
    currentStudent: Registration;
    attendance: {
        loading: boolean;
        success: boolean;
        data?: Attendance[];
    };
    diaryStatus: {
        loading: boolean;
        success: boolean;
        data?: DiaryStatus;
    };
    statistics: {
        loading: boolean;
        success: boolean;
        data?: Statistics;
    };
};

export const AttendanceReport: React.FC<AttendanceProps> = ({
    currentStudent,
    attendance,
    diaryStatus,
    statistics,
}) => {
    const getTextType = (textType: string) => {
        return QuestionTypeTitleEnum[textType as ContentTypeEnum];
    };

    return (
        <Row>
            <Col span={24}>
                <Flex gap={12}>
                    <Title level={3} style={{ margin: 0 }}>
                        {currentStudent.surname} {currentStudent.student_name}
                    </Title>
                    {/*<Button*/}
                    {/*    type="text"*/}
                    {/*    onClick={() => setCurrentStudent(null)}*/}
                    {/*>*/}
                    {/*    Сброс*/}
                    {/*</Button>*/}
                </Flex>
            </Col>

            {attendance.loading && <Spinner />}

            {attendance.success && !!attendance.data?.length && (
                <Col span={24}>
                    <Flex gap={15} vertical>
                        <Row gutter={[16, 16]}>
                            <Col md={8} xs={24}>
                                <div>
                                    Когорта: {attendance.data[0].cohort_number}
                                </div>
                                <div>
                                    Перевод:{' '}
                                    {getTextType(attendance.data[0].text_type)}
                                </div>
                                <div>
                                    Сложность:{' '}
                                    {getTextType(
                                        attendance.data[0].question_type
                                    )}
                                </div>
                            </Col>
                            <Col md={8} xs={24}>
                                <DiaryStatusCard
                                    data={diaryStatus.data}
                                    success={diaryStatus.success}
                                    loading={diaryStatus.loading}
                                />
                            </Col>
                            <Col md={8} xs={24}>
                                <StatisticsCard
                                    data={statistics.data}
                                    loading={statistics.loading}
                                    success={statistics.success}
                                />
                            </Col>
                        </Row>
                        <div></div>
                        <Flex gap={15} vertical>
                            {attendance.data.map((a, i) => (
                                <Card key={i}>
                                    <Space direction="vertical">
                                        <Text type="secondary">
                                            Текст Библии:
                                        </Text>
                                        <Text>{a.bible_text}</Text>
                                        <Text type="secondary">Вопрос:</Text>
                                        <Text>{a.content_value}</Text>
                                        <Text type="secondary">Ответ:</Text>
                                        <Text>{a.answer}</Text>
                                    </Space>
                                </Card>
                            ))}
                        </Flex>
                    </Flex>
                </Col>
            )}

            {attendance.success && !attendance.data?.length && (
                <div>Нет данных</div>
            )}
        </Row>
    );
};
