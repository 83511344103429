import React from 'react';
import { Breadcrumb, Flex, Form, FormProps } from 'antd';
import { routes } from '../../routes';
import { useNavigate, useParams } from 'react-router-dom';
import { Question } from '../../types/question';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiUrl } from '../../api/requests';
import axios from 'axios';

import { Success } from '../../components/success';
import { QuestionForm } from '../../components/question/question-form';
import { Error } from '../../components/error';
import { PageSpinner } from '../../components/page-spinner';

export type QuestionCreate = Omit<Question, 'id' | 'created_at'>;

export const EditQuestion: React.FC = () => {
    const [form] = Form.useForm<QuestionCreate>();
    const navigate = useNavigate();
    const { id } = useParams();

    const {
        isPending: isGetPending,
        isError: isGetError,
        isSuccess: isDataSuccess,
        data,
    } = useQuery<Question>({
        queryKey: ['editQuestion'],
        queryFn: async () => {
            const result = await axios.get(`${apiUrl}/questions/${id}`, {
                withCredentials: false,
            });
            return result.data;
        },
    });

    const {
        mutate: edit,
        isSuccess: isEditSuccess,
        isError: isEditError,
        reset,
        isPending: isEditPending,
    } = useMutation({
        mutationFn: (newQuestion: QuestionCreate) =>
            axios.put(`${apiUrl}/questions/${id}`, newQuestion, {
                withCredentials: false,
            }),
    });

    if (isDataSuccess) {
        form.setFieldsValue(data);
    }

    const handleSubmit: FormProps<QuestionCreate>['onFinish'] = (values) => {
        edit(values);
    };

    const toQuestions = () => navigate(routes.questions);

    if (isGetPending) return <PageSpinner />;

    if (isEditSuccess)
        return (
            <Success
                title="Вопрос успешно изменен"
                buttonTitle="Перейти в вопросы"
                onClickButton={toQuestions}
            />
        );

    if (isGetError || isEditError)
        return (
            <Error
                title="Ошибка на сервере"
                subTitle="Попробуйте снова"
                hasButton
                buttonTitle="Изменить вопрос"
                onClickButton={() => reset()}
            />
        );

    return (
        <Flex gap="middle" vertical>
            <Breadcrumb>
                <Breadcrumb.Item onClick={toQuestions}>Вопросы</Breadcrumb.Item>
                <Breadcrumb.Item>Изменить</Breadcrumb.Item>
            </Breadcrumb>
            <QuestionForm
                isDisabled={isEditPending}
                form={form}
                isEdit
                handleSubmit={handleSubmit}
            />
        </Flex>
    );
};
