import React from 'react';
import { Breadcrumb, Flex, Form, FormProps } from 'antd';
import { routes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { Question } from '../../types/question';
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from '../../api/requests';
import axios from 'axios';

import { Success } from '../../components/success';
import { QuestionForm } from '../../components/question/question-form';
import { Error } from '../../components/error';

export type QuestionCreate = Omit<Question, 'id' | 'created_at'>;

export const AddQuestion: React.FC = () => {
    const [form] = Form.useForm<QuestionCreate>();
    const navigate = useNavigate();

    const { mutate, isSuccess, isError, reset, isPending } = useMutation({
        mutationFn: (newQuestion: QuestionCreate) =>
            axios.post(`${apiUrl}/questions/`, newQuestion, {
                withCredentials: false,
            }),
    });

    const handleSubmit: FormProps<QuestionCreate>['onFinish'] = (values) => {
        mutate(values);
    };

    const toQuestions = () => navigate(routes.questions);

    if (isSuccess)
        return (
            <Success
                title="Вопрос успешно добавлен"
                buttonTitle="Добавить новый"
                onClickButton={() => reset()}
            />
        );

    if (isError)
        return (
            <Error
                title="Ошибка на сервере"
                subTitle="Попробуйте снова"
                hasButton
                buttonTitle="Добавить вопрос"
                onClickButton={() => reset()}
            />
        );

    return (
        <Flex gap="middle" vertical>
            <Breadcrumb>
                <Breadcrumb.Item onClick={toQuestions}>Вопросы</Breadcrumb.Item>
                <Breadcrumb.Item>Добавить</Breadcrumb.Item>
            </Breadcrumb>
            <QuestionForm
                isDisabled={isPending}
                form={form}
                handleSubmit={handleSubmit}
            />
        </Flex>
    );
};
